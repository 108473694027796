<template>
    <b-container fluid v-if="associate">
        <b-row>
            <b-col cols="6" class="print-show">
                <mara-table :item="associate.client"></mara-table>
            </b-col>
            <b-col cols="12" class="align-items-center justify-content-center text-center">
                <h3>Podaci o suradniku</h3>
            </b-col>
        </b-row>
        <b-row>
            <b-col md="12" class="p-4 align-items-center justify-content-center">
                <table class="table b-table table-striped table-hover table-bordered text-center align-middle">
                    <thead>
                    <tr>
                        <td><b>Ime:</b> {{associate.name}}
                        </td>
                    </tr>
                    <tr>
                        <td><b>Adresa:</b> {{associate.address}}
                        </td>
                    </tr>
                    <tr v-if="associate.phone">
                        <td><b>Telefon:</b> {{associate.phone}}
                        </td>
                    </tr>
                    <tr v-if="associate.city">
                        <td><b>Grad:</b> {{associate.city}}
                        </td>
                    </tr>
                    <tr v-if="associate.township">
                      <td><b>Grad (Novo):</b> {{associate.township.name}}
                      </td>
                    </tr>
                    <tr v-if="associate.activity">
                        <td><b>Djelatnost:</b> {{associate.activity.name}}
                        </td>
                    </tr>
                    <tr v-if="associate.id_number">
                        <td><b>ID broj:</b> {{associate.id_number}}
                        </td>
                    </tr>
                    <tr v-if="associate.pdv_number">
                        <td><b>PDV broj:</b> {{associate.pdv_number}}
                        </td>
                    </tr>
                    <tr v-if="associate.code">
                        <td><b>Šifra:</b> {{associate.code}}
                        </td>
                    </tr>
                    <tr v-if="associate.type">
                        <td v-if="associate.type==1"><b>Tip:</b> Kupac
                        </td>
                        <td v-if="associate.type==2"><b>Tip:</b> Dobavljač
                        </td>
                    </tr>
                    </thead>
                </table>
            </b-col>
            <b-col class="p-4 flex-center flex-column">
                <b-row class="p-2 w-75">
                    <router-link class="w-100" :to="$route.path + '/edit'">
                        <b-btn size="lg" variant="primary" class="w-100">
                            Uredi suradnika
                        </b-btn>
                    </router-link>
                </b-row>
            </b-col>
            <b-col md="6" class="p-4 flex-center flex-column">
                <b-row class="p-2 w-75">
                    <b-btn size="lg" variant="danger" class="w-100" v-b-modal.deleteAssociate>
                        Izbriši suradnika
                    </b-btn>
                </b-row>
            </b-col>
        </b-row>
        <b-modal centered
                 id="deleteAssociate" title="Potvrda"
                 @ok="deleteAssociate"
                 ok-variant="danger">
            <p class="my-4">Jeste li sigurni da želite izbrisati suradnika?</p>
        </b-modal>
    </b-container>

</template>

<script>
    export default {
        name: 'Associate',
        data () {
            return {
                associate: null,
            }
        },
        methods: {
            deleteAssociate: function() {
                axiOsta.delete(api.associates + this.associate.id).
                then(response => {
                    this.$router.push('/associates');
                })
            }
        },
        created() {
            axiOsta.get(api.associates + this.$route.params.id).then(response => {
                if (response.status === 200) {
                    this.associate = response.data;
                }
            })
        }
    }
</script>
